import { isDesktop } from './device';
import log from '../helpers/log';

export const seenthis = (adUnitPath: string, domId: string) => {
  window.highImpactJs = window.highImpactJs || {
    cmd: [],
  };

  const sizes = isDesktop() ? [[1920, 1080]] : [[1080, 1920]];

  const addMidScrollScript = () => {
    window.highImpactJs.cmd.push(() => {
      window.highImpactJs.defineSlot({
        slot: adUnitPath,
        template: 'midscroll',
        sizes: sizes,
        adUnitId: domId,
        waitForAdSignal: true,
      });
    });
  };
  const isTopAds = domId.includes('top_ad');
  if (isTopAds) {
    window.highImpactJs.cmd.push(() => {
      window.highImpactJs.setConfig({
        topBarHeight: isDesktop() ? 0 : 60,
        plugins: ['gam'],
        zIndex: 199,
      });

      window.highImpactJs.setTemplateConfig('topscroll', {
        peekAmount: '70vh',
      });

      window.highImpactJs.defineSlot({
        slot: adUnitPath,
        template: 'topscroll',
        sizes: sizes,
        adUnitId: domId,
        waitForAdSignal: true,
      });
    });

    addSeenthisStyle();
  } else {
    addMidScrollScript();
    midscrollerHandling(domId);
  }
};

const addSeenthisStyle = () => {
  const seenthisStyle = document.createElement('style');
  seenthisStyle.textContent = `
      body.high-impact-topscroll-rendered #__next {
        position: relative !important;
      }
      body.high-impact-topscroll-rendered:not(.high-impact-topscroll-is-hidden) #top-nav {
        position: absolute;
        max-height: unset !important;
      }
    `;
  document.head.appendChild(seenthisStyle);
};

const observers: { [key: string]: MutationObserver[] } = {};

const midscrollerHandling = (domId: string) => {
  const uniqueId = domId.split('-')[1] || 'default';
  const targetDiv = document.getElementById(domId) as HTMLElement;

  if (!targetDiv) return;

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        if (targetDiv.classList.contains('high-impact-ad-wrapper-midscroll')) {
          log("SeenthisMidscroller - Found div class 'high-impact-ad-wrapper-midscroll'", uniqueId);

          initMidscrollerVideoHide(targetDiv);

          observer.disconnect();
          // Assume that all bids are made for uniqueId and midscrollers placements are defined in markup
          setTimeout(() => {
            log(`SeenthisMidscroller - MutationObserver.disconnect() for ${uniqueId}`);
            observers[uniqueId]?.forEach((observer) => observer.disconnect());
          }, 2000);
        }
      }
    });
  });
  observer.observe(targetDiv, { attributes: true, attributeFilter: ['class'] });

  if (!observers[uniqueId]) {
    observers[uniqueId] = [];
  }
  observers[uniqueId].push(observer);
  setTimeout(() => {
    observer.disconnect();
  }, 10000); // Cleanup if no midscroller ad is found
};

const initMidscrollerVideoHide = (adUnitDiv: HTMLElement) => {
  // Find all jw-wrapper elements and hide them when the midscroller ad is visible
  const jwWrappers = Array.from(document.querySelectorAll('.jw-wrapper')).filter(
    (el) => el instanceof HTMLElement,
  ) as HTMLElement[];

  log('SeenthisMidscroller - Video hiding for ', jwWrappers);

  if (jwWrappers.length <= 0) return;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        jwWrappers.forEach((el: HTMLElement) => (el.style.opacity = entry.isIntersecting ? '0' : '1'));
      });
    },
    { threshold: 0 },
  );

  jwWrappers.forEach((el: HTMLElement) => el.style.setProperty('transition', 'opacity 0.5s ease 0s', 'important'));
  observer.observe(adUnitDiv);
};
