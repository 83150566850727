import waitFor from '../helpers/waitfor';
import getTcfConsent, { TCData } from './tcfv2';
import log from '../helpers/log';

export const checkForUserConsent = async (): Promise<boolean> => {
  if (
    typeof window !== 'undefined' &&
    window.userConsent &&
    typeof window.userConsent.isTargetingApproved === 'function'
  ) {
    return new Promise((resolve) => {
      window.userConsent.isTargetingApproved((response: any) => {
        resolve(response ? true : false);
      });
    });
  } else {
    log('userConsent is not available');
    return false;
  }
};

const initTcf = async (): Promise<TCData | undefined> => {
  log('initTcf ...');

  try {
    const { tcData } = await getTcfConsent();
    log('getTcfConsent resolved', tcData);
    return tcData;
  } catch (e) {
    console.error('Unable to retrieve tcf consent');
  }

  log('initTcf DONE');
};

const initConsent = async (): Promise<TCData | undefined> => waitFor(() => typeof window.__tcfapi !== 'undefined').then(initTcf);

export default initConsent;
