import log from "../helpers/log";
import { getDevice } from "./device";
import { getSiteName, getHostName, resolveHost } from "./domain";
import { resolveCountryCode } from "./domain";
import { getModules } from "./modules";

export type LoadedByAdsLib = {
    demandManager?: boolean,
    config?: boolean,
};

type AdsData = {
    adsConfig?: IAdsConfig // response from https://se-ads-microservice.ads.allermedia.io/api/v1/config/get[site]
    adsUniqueId?: string;
};

export type AdsConfigJson = {
    siteName?: string;
    hostName?: string;
    usePlacementCheck?: boolean;
    pageMeta?: {
        isNativeAd?: boolean;
        hideAds?: '0' | '1';
        device?: 'mobile' | 'desktop';
    };
    adsData?: AdsData;
    preConfig?: {
        growthbooks?: GrowthbookKeyValue[];
        enableDemandManager?: 'true' | 'false';
        enableAdnami?: 'true' | 'false';
    }; // response from https://se-ads-microservice.ads.allermedia.io/api/v1/config/preFetch?site_id=[site]
    topAdTarget?: string;   // div id for top ad placement
    loadedByAdsLib?: LoadedByAdsLib;
    useAma?: boolean;  
};
export const parseAdsConfig = (adsConfigJson: string | undefined) : AdsConfigJson => adsConfigJson ? JSON.parse(adsConfigJson) : {};

export const usePlacementCheck = () => window.ama?.usePlacementCheck || window.usePlacementCheck;

export const useTopAds = () => window.ama?.useTopAds || window.useTopAds;

const resolveTrueFalse = (candidate: boolean | string | undefined, defaultVal: boolean = true): boolean => {
    if (typeof candidate === 'string') return candidate === 'true';
    if (typeof candidate === 'boolean') return candidate;
    return defaultVal;
};

export const loadConfig = (adsConfigJson: AdsConfigJson) => {

    const {
        siteName,
        hostName,
        usePlacementCheck,
        pageMeta = {},
        adsData = { adsConfig: window.ama?.accConfig || window.accConfig },
        preConfig = {},
        loadedByAdsLib,
        useAma,
    } = adsConfigJson;
    
    resolveHost(hostName);

    // Default is to load config
    const loadConf = loadedByAdsLib && typeof loadedByAdsLib.config !== 'undefined' ? loadedByAdsLib.config : true

    if (loadConf) {

        log('loadedByAdsLib?.config ...  ');

        const host = `${getHostName()}`;
        const site = `${siteName || getSiteName()}`;

        log('host site: ', host, site);

        const displayAds = !(pageMeta?.isNativeAd || pageMeta?.hideAds === '1') ? 'true' : 'false';
        const isNativeAd = pageMeta?.isNativeAd || pageMeta?.hideAds === '1';
        const adsDevice = pageMeta?.device || getDevice();
        
        const adsUniqueId = typeof adsData.adsUniqueId !== 'undefined' ? `${adsData.adsUniqueId}` : undefined;
        const { adsConfig } = adsData;
        
        const growthbooks = preConfig.growthbooks || [];
        const defaultEnableDemandManager = true;
        const defaultFilterAds = true;
        const adsUseDemandManager =
            typeof preConfig?.enableDemandManager === 'undefined'
            ? defaultEnableDemandManager
            : preConfig.enableDemandManager === 'true';

        const enableTopAd =
            typeof preConfig?.enableAdnami === 'undefined'
            ? true
            : preConfig.enableAdnami === 'true';

        const countryCode = resolveCountryCode();
        const useAMA = useAma || window.ama || countryCode !== 'se';

        log('useAMA loadConfig ', useAMA, window.ama, useAma);

        if(useAMA) {
            log('useAMA  window.ama?.adsUniqueId: ', window.ama?.adsUniqueId, adsUniqueId);

            window.ama = { 
                siteName: site,
                hostName: host,
                usePlacementCheck: !!usePlacementCheck,        
                adsUniqueId: window.ama?.adsUniqueId || window.adsUniqueId || adsUniqueId,         
                displayAds: `${displayAds}`,
                adsUseDemandManager: `${adsUseDemandManager}`,
                useTopAds: enableTopAd,
                autoloadAds: resolveTrueFalse(window.ama?.autoloadAds),
                device: `${adsDevice}`,
                filterAds: `${defaultFilterAds}`,
                isNativeAd: `${isNativeAd}`, 
                ...window.ama,  
            }
            const { useGrowthbook } = getModules();
            if(useGrowthbook) {
                window.ama.growthbooks = growthbooks;
            }
            window.device = `${adsDevice}`; // Needed for demandManager

        } else {
            // Deprecated, will be removed when all platforms/users has window.ama
            window.siteName =  site;        
            window.hostName =  host;        
            window.adsUniqueId = window.adsUniqueId || adsUniqueId;            
            window.displayAds = `${displayAds}`;
            window.adsUseDemandManager = `${adsUseDemandManager}`;
            window.useTopAds = enableTopAd;            
            window.autoloadAds = resolveTrueFalse(window.autoloadAds);
            window.device = `${adsDevice}`;
            window.filterAds = `${defaultFilterAds}`;
            window.growthbooks = growthbooks;
            window.isNativeAd = `${isNativeAd}`;
            window.usePlacementCheck = !!usePlacementCheck;
        }
        log('Platform adsConfig is loaded');
        return adsConfig;            
    } else {
        log('Platform adsConfig not loaded by ads-lib');
    }
}

