import AdsManager from './AdsManager';
import { configureForDevelopment } from './placement/local';
import { enableGrowthbook, getGrowthbookGroupVideo } from './abtest/growthbook';
import adSettings from './config/adsettings';
import { getAdScheduleId } from './config/adschedule';
import ppid from './config/ppid';
import adConfig, { modifyMainCategoryPlacementsDivs } from './config/adslots';
import initConsent, { checkForUserConsent } from './consent/consent';
import initGpt from './googletag/googletag';
import log from './helpers/log';
import { timeFor } from './helpers/time';
import { collector } from './adstats/collector';
import waitFor from './helpers/waitfor';
import { initAdResources } from './resource/resource';
import { isVideoPage } from './config/domain';
import { isMainAndCategoryPage, stringIfAny } from './helpers/utils';
import { getModules } from './config/modules';
import { hasTcfConsent } from './consent/tcfv2';
import { getHostName } from './config/domain';
import { parseAdsConfig } from './config/platform';

log(`Start running adsBundle ${timeFor('runBundle')} ms`);

let processedIds: string[] = [];
const processed = { divIds: [] as string[] };
let serverLoadedConfig: IAdsConfig | undefined;

const hasUniqueIdAndNotProcessed = (uniqueId: string | number) => isUniqueIdDefined(uniqueId) && !isProcessed(uniqueId);
const isUniqueIdDefined = (id: string | number) => typeof id !== 'undefined';
const isProcessed = (uniqueId: string | number) => processedIds.includes(`${uniqueId}`);
const isValidUniqueId = (uniqueId: string | number, currentProcessed: number) =>
  currentProcessed > 0 ? hasUniqueIdAndNotProcessed(uniqueId) : isUniqueIdDefined(uniqueId);

const isPlacementsLoaded = (uniqueId: string) =>
  window.ama ? window.ama?.domLoaded?.[uniqueId] : window?.loadedAdPlacement?.[uniqueId];

/**
 * Initializes the loading of ads on a page.
 * @param uniqueId The unique ID appended to placements div-ids.
 * @param options Custom settings, e.g., { dfpSection = "..." }.
 * @returns A Promise that resolves once the initialization is complete.
 */
const initializeAds = async (
  adsUniqueId: string | number = window.ama?.adsUniqueId || window.adsUniqueId,
  options: InitOptions = {},
): Promise<void> => {
  log('initializeAds()', adsUniqueId, options, timeFor('initializeAds'), ' ms');

  const { useStats, useTADads, useGrowthbook, usePlacementCheck, useDivModificationsOnMainCat } = getModules();
  try {
    log('adsUniqueId, stringIfAny(adsUniqueId)', adsUniqueId, stringIfAny(adsUniqueId));
    let uniqueId = stringIfAny(adsUniqueId);
    let { videoOnly, dfpSection, isSecondArticle = false } = options;
    const currentProcessed = processedIds.length;

    if (!videoOnly && !isValidUniqueId(uniqueId, currentProcessed)) {
      processedIds.includes(uniqueId) && console.warn(`Ads:: already processed ${uniqueId}`);
      log(
        `Ads:: Valid uniqueId not set yet, waiting for updated ${
          window.ama ? 'window.ama.adsUniqueId' : 'window.adsUniqueId'
        } try no ...`,
      );
      await waitFor(
        () => isValidUniqueId(stringIfAny(window.ama?.adsUniqueId || window.adsUniqueId), currentProcessed),
        {
          timeout: 10000,
          errorMessage: `Timeout: Valid adsUniqueId is missing - ${uniqueId} `,
        },
      );

      // We cancel init if adsUniqueId is processed anyway.
      if (isProcessed(window.ama?.adsUniqueId || window.adsUniqueId)) {
        log('initializeAds() was cancelled due to already processed ', adsUniqueId, options);
        return;
      }
      uniqueId = stringIfAny(window.ama?.adsUniqueId || window.adsUniqueId);
    }

    !uniqueId && console.warn('Ads:: uniqueId is missing. it might be set to late');

    uniqueId && log(`Ads:: Valid uniqueId '${uniqueId}' set after ${timeFor('id')} ms`);

    processedIds.push(uniqueId);

    adSettings.refresh();

    log('Get config for ', uniqueId, getHostName(), adSettings.device, { ...adSettings });

    const pageNo = [...processedIds].length;

    timeFor('confLoad');
    useDivModificationsOnMainCat && isMainAndCategoryPage() && modifyMainCategoryPlacementsDivs();
    let config: any = await adConfig.getModifiedConfig(pageNo, serverLoadedConfig);

///////////////////
//////////////////////////////////////////
/////////////

    config = adConfig.addExtraPlacementsToConfig(config, 'intext', processed);
    config = adConfig.addExtraPlacementsToConfig(config, 'responsive', processed);

    if (usePlacementCheck && !videoOnly) {
      log('usePlacementCheck', usePlacementCheck);

      if (!isPlacementsLoaded(uniqueId)) {
        log(`Ads:: Wait for Placement Wrappers to be loaded for ${uniqueId} ...`);
        await waitFor(() => isPlacementsLoaded(uniqueId), {
          timeout: 20000,
          errorMessage: `Timeout: Placementwrappers are missing for ${uniqueId}`,
        });
      }
      log('Ads:: Placement Wrappers Loaded for ', uniqueId);
    }

    let filteredAds: any = adSettings.filterAds ? adConfig.filter(config, adSettings.isNativeAd) : config;

    log(`filteredConfig ${timeFor('confLoad', 'stop')} ms `, filteredAds);

    const tcData = await initConsent();

    log(`Got Consent ${timeFor('consent')} ms`);

    initGpt();

    useStats && collector.start(config?.statscollector);

    useGrowthbook && (await enableGrowthbook());
    const hasConsent = (useTADads && (await checkForUserConsent())) || hasTcfConsent(tcData);
    if (useTADads && !hasConsent) {
      log(`useTADads`);

      if (window.ama) {
        window.ama.adsUseDemandManager = 'false';
      } else {
        window.adsUseDemandManager = 'false';
      }
      dfpSection = 'optout';
    }
    AdsManager.init(filteredAds, ppid.get(), uniqueId, dfpSection, pageNo, hasConsent, videoOnly, isSecondArticle);
  } catch (error) {
    console.error('Error initializing ads:', error);
  }
};

// get attribute ads-conf from current script
const adsPlatformConfJson = document.getElementById('ads-manager')?.getAttribute('data-ads-conf') || undefined;

const parsedConfig = parseAdsConfig(adsPlatformConfJson);

log('adsPlatformConfJson', parsedConfig);

initAdResources(parsedConfig)
  .then((config) => {
    log('AdResources loaded');

    if (config) {
      serverLoadedConfig = config;
    }

    if (window.ama) {
      window.ama.init = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {
        log('DFPInit - window.ama.init called initializeAds ...', options);

        if (options?.dfpSection === 'general') {
          // Remove posibility to override to a general section
          // ToDo: Remove when response from ads-microservice no longer has dfpSection
          options = { ...options, dfpSection: undefined };
        }
        log('options', options);

        initializeAds(uniqueId, options);
      };
      log('window.ama.init available');
    }
    window.DFPInit = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {
      log('DFPInit - window.DFPInit called initializeAds ...', options);

      if (options?.dfpSection === 'general') {
        // Remove posibility to override to a general section
        // ToDo: Remove when response from ads-microservice no longer has dfpSection
        options = { ...options, dfpSection: undefined };
      }
      log('options', options);

      initializeAds(uniqueId, options);
    };
    log('window.DFPInit available');

    adSettings.init();
    const options: InitOptions = { videoOnly: isVideoPage() };

    adSettings.autoloadAds && initializeAds(window.ama?.adsUniqueId || window.adsUniqueId, options);

    window.ads = {
      getAdScheduleId,
      getVideoExperimentGroup: getGrowthbookGroupVideo,
      getVideoPpId: ppid.get,
    };
    if (window.ama) {
      window.amav = {
        getAdScheduleId,
        getVideoExperimentGroup: getGrowthbookGroupVideo,
        getVideoPpId: ppid.get,
      };
      log('window.ama.video available');
    }

    log('window.ads available');
  })
  .catch((error) => {
    console.error('AdResources fail to load', error);
  });
