export const prebidstats = (): any => {
  
  if (window.ama) {
    window.ama.adStats.add([
      {
        placement: 'prebid',
        gptEvent: 'slotRequested',
      },
    ]);
    window.ama.adStats.push();

  } else {
    window.adStats.add([
      {
        placement: 'prebid',
        gptEvent: 'slotRequested',
      },
    ]);
    window.adStats.push();  
  }
  
};
