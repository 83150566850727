import adConfig from "./adslots";
import adSettings from './adsettings';

/**
 * Resolves the ad schedule ID based on the provided configuration and start type.
 * @param config The ad configuration.
 * @param startType The type of ad start.
 * @returns The resolved ad schedule ID.
 */
const resolveAdScheduleId = (config: any, startType: string): string =>
  config && config.video ? config.video[startType] : null;

/**
 * Asynchronously retrieves the ad schedule ID.
 * @param startType The type of ad start.
 * @returns A Promise that resolves to the ad schedule ID.
 */
export const getAdScheduleId = async (startType: string = 'autoPlay'): Promise<string> => {
  try {
    const config = await adConfig.get();
    return resolveAdScheduleId(config, startType);
  } catch (error) {
    console.error('Error retrieving ad schedule ID:', error);
    return null;
  }
};